/*
  This is the login page. It accepts username and password states then stored in the users reducer.
  Once the submit button is clicked, it triggers the request from the api. If user is authenticated
  it will redirect to the portal landing page.
*/
import React, { useEffect, useState } from "react";
import {
  Input,
  FormGroup,
  Label,
  Container,
  Button,
  Form,
  Spinner,
  Badge,
  FormText,
} from "reactstrap";
import { AddAccountModal, AuthContainer } from "../../../Layouts/Portal";
import { useDispatch, useSelector } from "react-redux";
import { authUser, setRememberMe } from "../../../features/Portal/userSlice";
import { useNavigate } from "react-router-dom";
import { getOffices } from "../../../features/Portal/officesSlice";
import { getRoles } from "../../../features/Portal/rolesSlice";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

const Login = () => {
  const dispatch = useDispatch();
  const { isLoading, loginAttempts, errorMessage, rememberMe, user } =
    useSelector((state) => state.user);

  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isRegisterOpen, toggleRegistration] = useState(false);


  const userCredentials = {
    username: username,
    password: password,
  };

  const onToggleRegistration = () => {
    toggleRegistration(!isRegisterOpen);
  }

  const onFormChanged = (e) => {
    const fielId = e.target.getAttribute("id");

    switch (fielId) {
      case "username":
        setUsername(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (rememberMe) {
      window.localStorage.setItem(
        "userCredentials",
        JSON.stringify({ username: userCredentials.username })
      );
    }
    dispatch(authUser(userCredentials, navigate));
  };

  useEffect(() => {
    dispatch(getOffices())
    dispatch(getRoles())
    if (window.localStorage.getItem("userCredentials")) {
      const userCredentials = JSON.parse(
        window.localStorage.getItem("userCredentials")
      );
      setUsername(userCredentials.username);
    }

    if (window.localStorage.getItem("accessToken") || user.user_2fa) {
      navigate("/");
    }
  }, [navigate, user]);

  return (
    <AuthContainer
      title={"Department of Agriculture Regional Field Office 2 Portal"}
    >
      <Container className="p-5">
        <ToastContainer />
        <AddAccountModal title="Registration Form" isModalOpen={isRegisterOpen} toggleModal={toggleRegistration} register />
        <Form onSubmit={onFormSubmit}>
          <h2 className="text-center">Log In</h2>
          <FormGroup>
            <Label for="username">Username</Label>
            <Input
              invalid={errorMessage === "Wrong Username" && true}
              id="username"
              name="username"
              type="text"
              value={username}
              onChange={onFormChanged}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              invalid={errorMessage === "Wrong Password" && true}
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={onFormChanged}
              required
            />
          </FormGroup>
          <FormGroup>
            <Input
              id="chkRemember"
              name="remember_me"
              type="checkbox"
              onClick={() => dispatch(setRememberMe())}
            />
            &nbsp;
            <Label for="chkRemember">Remember me</Label>
          </FormGroup>
          {errorMessage !== "" && (
            <FormGroup className="d-flex flex-column">
              <Badge color="danger" className="mb-2 text-wrap">
                {errorMessage}
              </Badge>
            </FormGroup>
          )}
          {loginAttempts > 0 && (
            <FormGroup className="d-flex flex-column">
              <Badge color="danger">
                You only have ({5 - loginAttempts}) attempts
              </Badge>
            </FormGroup>
          )}
          {Cookies.get('sessionExpired') && (
            <FormGroup className="d-flex flex-column">
              <Badge color="danger">
                Session Expired. Please Login Again.
              </Badge>
            </FormGroup>
          )}
          <FormGroup>
            <FormText className="text-center d-block">
              Doesn't have an account yet?{" "}
              <Button onClick={onToggleRegistration} href="#" tag="span" size="sm" color="primary" outline>
                Register here
              </Button>
            </FormText>
          </FormGroup>
          <FormGroup>
            <Button className="bg-light-green" block type="submit">
              {isLoading ? <Spinner color="light" /> : "Submit"}
            </Button>
          </FormGroup>
        </Form>
      </Container>
    </AuthContainer>
  );
};

export default Login;
