/*
  This component is the main component of the portal. 
  It controls the view of different routes.
  It contains different routes the user can access within the portal.
*/
import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Layouts/Portal/ProtectedRoute/ProtectedRoute";
import { ErrorBoundary, Main, RequirePermission } from "./Layouts/Portal";
import { Container } from "reactstrap";
import {
  Homepage,
  PageUnavailable,
  AdminPanel,
  Accounts,
  Roles,
  Login,
  DTS,
  LoadingApp,
  VerifyAuthenticator,
  UserProfile,
} from "./Pages/Portal/";
import {
  AddCommunication,
  Classfications,
  Drafts,
  MOC,
  Inbox,
  ViewCommunication,
  ViewTask,
  Search,
  Reports,
  CommunicationLogs,
  Analytics,
  HelpCenter,
} from "./Pages/DTS/";
import { io } from "socket.io-client";
import Calendar from "./Pages/DTS/Calendar/Calendar";
import { useDispatch, useSelector } from "react-redux";
import { getUserRolePermission } from "./features/Portal/rolesSlice";
import 'react-toastify/dist/ReactToastify.css';
import useListenStorage from "./App/Hooks/useListenStorage";

const App = () => {
  const { user } = useSelector((state) => state.user);
  const [socket, setSocket] = useState(null);
  const apiServer = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  useListenStorage();

  useEffect(() => {
    // const newSocket = io('http://localhost:3001');
    const newSocket = io(apiServer);
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  useEffect(() => {
    socket?.emit("addNewUser", user);
  }, [socket, user]);

  useEffect(() => {
    if(user && user.user_id) dispatch(getUserRolePermission())
  }, [user, dispatch])

  return (
    <Container fluid className="m-0 p-0 bg-light">
      <Router>
        <Suspense fallback={<LoadingApp />}>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route path="/authenticate" element={<VerifyAuthenticator />} />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<LoadingApp />}>
                    <UserProfile />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route
              index
              element={
                <ProtectedRoute>
                  <Suspense fallback={<LoadingApp />}>
                    <Homepage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="/dts"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<LoadingApp />}>
                    <DTS />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={
                <Suspense fallback={<LoadingApp />}>
                  <PageUnavailable />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/dts/managementOfCommunications"
            element={
              <ProtectedRoute>
                <MOC socket={socket} />
              </ProtectedRoute>
            }
          >
            <Route
              path="/dts/managementOfCommunications/add"
              element={
                <RequirePermission
                  isPage
                  allowedPermissions={"addCommunication"}
                >
                  <ErrorBoundary>
                    <Suspense fallback={<LoadingApp />}>
                      <AddCommunication socket={socket} />
                    </Suspense>
                  </ErrorBoundary>
                </RequirePermission>
              }
            />
            <Route
              path="/dts/managementOfCommunications/classifications"
              element={
                <RequirePermission
                  isPage
                  allowedPermissions={"manageClassifications"}
                >
                  <Suspense fallback={<LoadingApp />}><Classfications /></Suspense>
                </RequirePermission>
              }
            />
            <Route
              path="/dts/managementOfCommunications/inbox"
              element={<Suspense fallback={<LoadingApp />}><Inbox /></Suspense>}
            />
            <Route
              path="/dts/managementOfCommunications/inbox/viewCommunication/:inbox_id/:com_id"
              element={<Suspense fallback={<LoadingApp />}><ErrorBoundary><ViewCommunication /></ErrorBoundary></Suspense>}
            />
            <Route
              path="/dts/managementOfCommunications/inbox/viewTask/:inbox_id/:com_id/:actionChosen?"
              element={<Suspense fallback={<LoadingApp />}><ViewTask /></Suspense>}
            />
            <Route
              path="/dts/managementOfCommunications/search/:search_type"
              element={<Suspense fallback={<LoadingApp />}><Search /></Suspense>}
            />
            <Route
              path="/dts/managementOfCommunications/analytics"
              element={<Suspense fallback={<LoadingApp />}><Analytics /></Suspense>}
            />
            <Route
              path="/dts/managementOfCommunications/reports/"
              element={
                <RequirePermission allowedPermissions={"exportReport"} isPage>
                  <Suspense fallback={<LoadingApp />}><Reports /></Suspense>
                </RequirePermission>
              }
            />
            <Route
              path="/dts/managementOfCommunications/reports/:status/:com_ids"
              element={
                <RequirePermission allowedPermissions={"exportReport"} isPage>
                  <Suspense fallback={<LoadingApp />}><Reports /></Suspense>
                </RequirePermission>
              }
            />
            <Route
              path="/dts/managementOfCommunications/communicationLogs"
              element={<Suspense fallback={<LoadingApp />}><CommunicationLogs /></Suspense>}
            />
            <Route
              path="/dts/managementOfCommunications/calendar"
              element={<Suspense fallback={<LoadingApp />}><Calendar /></Suspense>}
            />
            <Route
              path="/dts/managementOfCommunications/draft/"
              element={<Suspense fallback={<LoadingApp />}><Drafts socket={socket} /> </Suspense>}
            />
            <Route
              path="/dts/managementOfCommunications/HelpCenter/"
              element={<Suspense fallback={<LoadingApp />}><HelpCenter /></Suspense>}
            />
            <Route
              path="/dts/managementOfCommunications/*"
              element={<Suspense fallback={<LoadingApp />}><PageUnavailable /></Suspense>}
            />
          </Route>
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Suspense fallback={<LoadingApp />}><AdminPanel /></Suspense>
              </ProtectedRoute>
            }
          >
            <Route
              index
              path="/admin/accounts"
              element={
                <RequirePermission isPage allowedPermissions={["manageAccounts", "assignRoleToUser", "viewAdminPanel"]}>
                  <Suspense fallback={<LoadingApp />}><Accounts /></Suspense>
                </RequirePermission>
              }
            />
            <Route
              path="/admin/roles"
              element={
                <RequirePermission isPage allowedPermissions={"manageRoles"}>
                  <Suspense fallback={<LoadingApp />}><Roles /></Suspense>
                </RequirePermission>
              }
            />
            <Route path="/admin/*" element={<Suspense fallback={<LoadingApp />}><PageUnavailable /></Suspense>} />
          </Route>
        </Routes>
        </Suspense>
      </Router>
    </Container>
  );
};

export default App;
