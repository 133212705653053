/*
    This component is the footer section of the portal page
*/
import moment from 'moment';
import React from 'react';

const Footer = () => {
    return(
        <div className='bg-green text-center py-4 text-super-light-green'>
            <p className='m-0'>DEPARTMENT OF AGRICULTURE</p>
            <p className='m-0'>ALL RIGHTS RESERVED {moment().format('YYYY')}</p>
        </div>
    )
}

export default Footer;