/*
  This is a navigation bar component of reactstrap/bootstrap. It contains the page title and dropdown controls and links (e.g.Profile, Adminp Panel, Logout)
*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Navbar,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Col,
} from "reactstrap";
import {
	getUser,
	logout,
	logoutUser,
} from "../../../features/Portal/userSlice";
import { StyledLink } from "../../../Assets/styles/style";
import { GiHamburgerMenu } from "react-icons/gi";
import IconatedButton from "../IconatedButton/IconatedButton";
import { StyledDiv } from "./Topbar.style";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import RequirePermission from "../RequirePermission/RequirePermission";

const Topbar = ({ className, appName, navItem, sidenav, toggleSideNav }) => {
	const user = useSelector((state) => state.user.user);
	const { emp_firstname } = user;
	const dispatch = useDispatch();

	const onLogout = () => {
		dispatch(logoutUser());
		dispatch(logout());
	};

	useEffect(() => {
		if (!user && !user.user_id && !user.user_2fa) dispatch(getUser());
	}, []);
	return (
		<Navbar className={className} expand="md" dark>
			<StyledDiv>
				<IconatedButton
					onClick={toggleSideNav}
					icon={<GiHamburgerMenu />}
					className="bg-green border-0 me-2"
				/>
			</StyledDiv>
			<Col
				style={{
					maxWidth: "50px",
					width: "10px",
					margin: "10px 10px 10px 10px",
				}}
			>
				<img
					src="https://i.imgur.com/On9nyfR.png"
					alt="DA-Logo"
					className="img-fluid"
				/>
			</Col>
			<Link to="/" className="navbar-brand">
				{appName}
			</Link>
			{navItem && (
				<Nav navbar className="me-auto d-none d-sm-block">
					<NavItem>
						<NavLink
							href="/dts/managementOfCommunications/inbox"
							className="text-light-green"
							active
						>
							{navItem}
						</NavLink>
					</NavItem>
				</Nav>
			)}
			{window.localStorage.getItem("accessToken") ? ( //if user is logged in
				<>
					{/* <NavbarToggler
            onClick={() => {
              setToggle(!toggle);
            }}
          /> */}
					{/* <Collapse navbar isOpen={toggle}> */}
					<Nav className="ms-auto" navbar>
						<UncontrolledDropdown nav>
							<DropdownToggle caret nav>
								Kamusta ka, {emp_firstname}!
							</DropdownToggle>
							<DropdownMenu end={true}>
								<RequirePermission allowedPermissions={"viewAdminPanel"}>
									<DropdownItem>
										<StyledLink to="/admin/accounts">Admin Panel</StyledLink>
									</DropdownItem>
									<DropdownItem divider />
								</RequirePermission>
								<DropdownItem>
									<StyledLink to="/profile">Profile</StyledLink>
								</DropdownItem>
								<DropdownItem onClick={onLogout}>Logout</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
					{/* </Collapse> */}
				</>
			) : (
				//if user is not logged in
				<></>
			)}
		</Navbar>
	);
};

export default Topbar;
