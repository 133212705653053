import React, { useState } from "react";
import { BsCheck2, BsX } from "react-icons/bs";
import { ModalBody, ModalFooter, Spinner } from "reactstrap";
import IconatedButton from "../IconatedButton/IconatedButton";
import ModalContainer from "../ModalContainer/ModalContainer";

const Confirmation = ({
  isOpen,
  toggleModal,
  onProceed,
  query = "Do you really want to proceed?",
  isLoading,
}) => {
  const [disableEvent, toggleDisable] = useState(false);
  const onYes = () => {
    toggleDisable(true);
    onProceed();
  };
  return (
    <ModalContainer
      isModalOpen={isOpen}
      toggleModal={toggleModal}
      title="Confirmation"
      size="md"
    >
      <ModalBody>
        <p className="lead">{query}</p>
      </ModalBody>
      <ModalFooter>
        <IconatedButton
          name="Cancel"
          color="danger"
          disabled={disableEvent} 
          onClick={toggleModal}
          icon={<BsX />}
        />
        <IconatedButton
          icon={isLoading ? <Spinner size={'sm'} /> : <BsCheck2 />}
          name={'Yes'}
          disabled={disableEvent}
          onClick={onYes}
        />
      </ModalFooter>
    </ModalContainer>
  );
};

export default Confirmation;
