/*
  Modal Form for Adding account under account module.
*/
import React, { useEffect, useState } from "react";
import { ModalContainer } from "../index";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  ModalFooter,
  FormFeedback,
  ModalBody,
  Container,
} from "reactstrap";
import IconatedButton from "../IconatedButton/IconatedButton";
import {
  createAccount,
  getAccounts,
} from "../../../features/Portal/accountsSlice";
import { toast } from "react-toastify";
import {
  createDropdownList,
  getNameValue,
} from "../../../Helpers/portal_helpers";

const AddAccountModal = ({
  isModalOpen,
  toggleModal,
  users = [],
  officeList = [],
  roleList = [],
  groups = [],
  title = "Add Account",
  register = false,
}) => {
  const { offices } = useSelector((state) => state.offices);
  const { roles } = useSelector((state) => state.roles);

  const officeDropdown = createDropdownList(
    getNameValue(
      offices.map((office) => ({
        ...office,
        office_code: `${office.office_code} - ${office.office_name}`,
      })),
      "office_code",
      "office_id"
    )
  );
  const roleDropdown = createDropdownList(getNameValue(roles, 'role_name', 'role_id'));
  const groupsDropdown = createDropdownList(groups);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isUsernameTaken, setUsernameTaken] = useState(false);
  const { addIsLoading, userAdded } = useSelector((state) => state.accounts);
  const dispatch = useDispatch();

  const checkPasswordMatch = () => {
    if (password === confirmPassword) {
      setIsPasswordMatch(true);
    } else {
      setIsPasswordMatch(false);
    }
  };

  const onChangeUsername = (e) => {
    const exist = users.filter((user) => {
      return user.user_username === e.target.value;
    });
    if (exist.length === 1) {
      setUsernameTaken(true);
    } else {
      setUsernameTaken(false);
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    checkPasswordMatch();

    const form_data_account = new FormData(e.target);
    if (register) form_data_account.set("mode", "register");
    const account = {};
    form_data_account.forEach(function (value, key) {
      account[key] = value;
    });
    

    const res = await dispatch(createAccount(account));
    if (register) return
    if(res.payload[0].user_username){
      toast.success(`${userAdded.user_username} is successfully registered.`, {
        autoClose: 1500,
        position: "top-center",
        onOpen: () => toggleModal(false),
      });
    }else if(res.payload === 'username_taken'){
      setUsernameTaken(true)
    }
  };

  useEffect(() => {
    if (!userAdded.user_username) return;
    toast.success(`Registered Successfully!`, {
      autoClose: 1500,
      position: "top-center",
      onOpen: () => {
        toggleModal(false);
        dispatch(getAccounts());
      },
    });
  }, [userAdded]);

  return (
    <ModalContainer
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      title={title}
    >
      <Form onSubmit={onFormSubmit}>
        <ModalBody>
          <Container>
            <h5>Account</h5>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    bsSize="sm"
                    id="username"
                    name="username"
                    onKeyUp={onChangeUsername}
                    type="text"
                    required
                    invalid={isUsernameTaken}
                  />
                  <FormFeedback>Username is already taken!</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    bsSize="sm"
                    id="password"
                    name="password"
                    type="password"
                    required
                    onKeyUp={(e) => {
                      setPassword(e.target.value);
                    }}
                    invalid={!isPasswordMatch}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="confPassword">Confirm Password</Label>
                  <Input
                    bsSize="sm"
                    id="confPassword"
                    name="confPassword"
                    type="password"
                    required
                    onKeyUp={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    invalid={!isPasswordMatch}
                  />
                  <FormFeedback>Password does not match.</FormFeedback>
                </FormGroup>
                {!register && (
                  <FormGroup>
                    <Label for="group_id">Group</Label>
                    <Input
                      bsSize="sm"
                      id="group_id"
                      name="group_id"
                      type="select"
                    >
                      <option value="">None</option>
                      {groupsDropdown}
                    </Input>
                  </FormGroup>
                )}
              </Col>
              <Col md="6">
                {/* <FormGroup>
                  <Label for="supervisor_id">Supervisor</Label>
                  <Input
                    bsSize="sm"
                    id="supervisor_id"
                    name="supervisor_id"
                    type="select"
                  >
                    <option value="">None</option>
                    {usersDropdown}
                  </Input>
                </FormGroup> */}

                <FormGroup className={register && "hidden"}>
                  <Label for="role_id">Role</Label>
                  <Input
                    bsSize="sm"
                    id="role_id"
                    name="role_id"
                    type="select"
                    required
                  >
                    {roleDropdown}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    bsSize="sm"
                    id="email"
                    name="email"
                    type="email"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <h5>Employee Data</h5>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="agency_id">Agency ID number</Label>
                  <Input
                    bsSize="sm"
                    id="agency_id"
                    name="agency_id"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="position">Position</Label>
                  <Input
                    bsSize="sm"
                    id="position"
                    name="position"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="lastname">Last Name</Label>
                  <Input
                    bsSize="sm"
                    id="lastname"
                    name="lastname"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="firstname">First Name</Label>
                  <Input
                    bsSize="sm"
                    id="firstname"
                    name="firstname"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="middlename">Middle Name</Label>
                  <Input
                    bsSize="sm"
                    id="middlename"
                    name="middlename"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="suffix">Suffix</Label>
                  <Input bsSize="sm" id="suffix" name="suffix" type="text" />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="contact_no">Contact Number</Label>
                  <Input
                    bsSize="sm"
                    id="contact_no"
                    name="contact_no"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="sex">Sex</Label>
                  <div>
                    <Label for="sex">Male&nbsp;</Label>
                    <Input name="sex" value="male" type="radio" required />
                    <Label for="sex" className="ms-3">
                      Female&nbsp;
                    </Label>
                    <Input name="sex" value="female" type="radio" required />
                  </div>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="birthdate">Date of birth</Label>
                  <Input
                    bsSize="sm"
                    id="birthdate"
                    name="birthdate"
                    type="date"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="office_id">Office</Label>
                  <Input
                    bsSize="sm"
                    id="office_id"
                    name="office_id"
                    type="select"
                    required
                  >
                    {officeDropdown}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <IconatedButton
            type="submit"
            size="sm"
            color="primary"
            name={addIsLoading ? "Saving..." : "Submit"}
            disabled={addIsLoading}
          />
          <IconatedButton
            size="sm"
            onClick={() => toggleModal()}
            name="Cancel"
            color="dark"
          />
        </ModalFooter>
      </Form>
    </ModalContainer>
  );
};

export default AddAccountModal;
