/*
  For controlling the view based on permissions
*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RequirePermission = ({ children, allowedPermissions, isPage }) => {
  const { userPermissions } = useSelector((state) => state.roles);
  const {user} = useSelector(state => state.user)
  const [isPermitted, setPermitted] = useState(false);

  useEffect(() => {
    if (typeof allowedPermissions === "string") {
      const isUserPermitted = userPermissions.includes(allowedPermissions);
      return setPermitted(isUserPermitted);
    } else {
      let isUserPermitted = allowedPermissions.map((perm) => {
        return userPermissions.includes(perm);
      });
      return setPermitted(isUserPermitted.includes(true));
    }
  }, [userPermissions, user]);

  if (isPermitted) {
    return <>{children}</>;
  } else {
    return isPage ? <h1>Not allowed</h1> : <></>;
  }
};

export default RequirePermission;
