/*
  This component is the container of the Authentication/Login Form.
*/
import React from "react";
import { CardBody, Col, Row } from "reactstrap";
import { StyledCard } from "./AuthContainer.style";
import Container from "react-bootstrap/Container";

const AuthContainer = ({ title, children }) => {
	return (
		<Container
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
				backgroundColor: "white", // Set the background color to white
			}}
		>
			<Row>
				<Col lg="6" md="5" sm="9" xs="12">
					<img
						src="https://i.imgur.com/OcbuGuZ.png"
						alt="DA-Logo"
						className="img-fluid"
					/>
				</Col>
				<Col lg="6" md="7" sm="3" xs="12">
					<StyledCard style={{ maxWidth: "500px", width: "500px" }}>
						<CardBody style={{ padding: 0 }}>{children}</CardBody>
					</StyledCard>
				</Col>
			</Row>
		</Container>
	);
};

export default AuthContainer;
