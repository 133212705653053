/*
  Portal helper functions
*/
export const checkPermission = (userPermissions, allowedPermissions) => {
  if (typeof allowedPermissions === "string") {
    const isUserPermitted = userPermissions.includes(allowedPermissions);
    return isUserPermitted;
  } else {
    let isUserPermitted = allowedPermissions.map((perm) => {
      return userPermissions.includes(perm);
    });
    return isUserPermitted.includes(true);
  }
};

export const createDropdownList = (lists, id) => {
  const distinctList = [
    ...new Map(lists.map((list) => [list["value"], list])).values(),
  ];
  const dropdownLists = distinctList.map((list, index) => {
    if (list.value === id) {
      return (
        <option key={index} value={list.value} selected>
          {list.name}
        </option>
      );
    } else {
      return (
        <option key={index} value={list.value}>
          {list.name}
        </option>
      );
    }
  });

  return dropdownLists;
};

export const getNameValue = (array, name, value) => {
  return array.map((item) => ({ name: item[name], value: item[value] }));
};
