import { useEffect } from 'react'


const useListenStorage = () => {
    useEffect(() => {
        window.addEventListener('storage', handleStorageChange);
    
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
      }, []);
    
      const handleStorageChange = (event) => {
        if (event.key === 'accessToken') {
          window.location.reload();
        }
      };
}

export default useListenStorage