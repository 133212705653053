/*
  This file is the main container of the portal page.
  This component contains the header, footer, and the content wrapper which contains the content of page.
*/
import React from "react";
import Header from "../Header/Header";
import Topbar from "../Topbar/Topbar";
import Footer from "../Footer/Footer";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import { Outlet } from "react-router-dom";
import ContentLoader from "../ContentLoader/ContentLoader";
import { useSelector } from "react-redux";

const Main = ({ children, contentBgColor }) => {
	const { isLoading } = useSelector((state) => state.user);
	return (
		<ContentLoader
			isLoading={false}
			loadingMessage="Fetching user data, please wait."
		>
			<ContentWrapper>
				<Outlet />
			</ContentWrapper>
			<Footer />
		</ContentLoader>
	);
};

export default Main;
