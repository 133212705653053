/*
  Button with icon component
*/
import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
const IconatedButton = (props) => {
  const { icon, name = "", size = "sm", tooltip, id, tooltipText } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      <Button
        color="primary"
        size={size}
        className="d-flex align-items-center"
        {...props}
        id={id}
      >
        {icon}
        {name !== "" && <>&nbsp;</>}
        {name}
      </Button>
      {tooltip && (
        <Tooltip isOpen={tooltipOpen} target={id} toggle={toggle}>
          {tooltipText}
        </Tooltip>
      )}
    </>
  );
};

export default IconatedButton;
