/*
  Users Reducer
   This file monitors states of the Login module
*/
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { isUndefined } from "lodash";

const apiServer = process.env.REACT_APP_API_URL;

export const authUser = createAsyncThunk(
  "user/authUser",
  async (userCredentials) => {
    return await fetch(apiServer + "/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userCredentials),
    }).then((res) => res.json());
  }
);

export const getUser = createAsyncThunk("user/getUser", async () => {
  return await fetch(apiServer + "/getUser", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      accessToken: window.localStorage.getItem("accessToken"),
    }),
  }).then((res) => res.json());
});

export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  return await fetch(apiServer + "/logout", {
    method: "get",
    headers: {
      Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
    },
  }).then((res) => res.json());
});

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (args) => {
    return await fetch(apiServer + "/changePassword", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(args),
    }).then((res) => res.json());
  }
);

export const enableDisable2fa = createAsyncThunk(
  "user/enableDisable2fa",
  async (args) => {
    return await fetch(apiServer + "/enableDisable2fa", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(args),
    }).then((res) => res.json());
  }
);

export const verifyPin = createAsyncThunk("user/verifyPin", async (args) => {
  const options = {
    method: 'POST',
    headers: {
      'X-RapidAPI-Key': '53b5b4ce63mshec0db547f226a8bp1dfb69jsn3413d34a1b49',
      'X-RapidAPI-Host': 'easy-authenticator.p.rapidapi.com'
    }
  };
  
  return await fetch(`https://easy-authenticator.p.rapidapi.com/verify?secretCode=${args.user_secret_code}&token=${args.otp}`, options)
    .then(response => response.json())
});

const initialState = {
  user: {},
  accessToken: "",
  isLoading: false,
  errorMessage: "",
  loginAttempts: 0,
  lockMessage: "",
  rememberMe: false,
  isLogin: false,
  is2faEnabled: null,
  isLoadingChangePassword: false,
  isLoadingToggle2fa: false,
  errorChangePassword: false,
  isVerifyPinLoading: false,
  isVerified: null,
  verifyPinError: ''
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setRememberMe: (state) => {
      state.rememberMe = !state.rememberMe;
    },
    logout: (state) => {
      state.isLogin = !state.isLogin;
      state.loginAttempts = 0;
      state.lockMessage = "";
      state.errorMessage = "";
      window.localStorage.removeItem("accessToken");
      state.user = {}
      Cookies.remove('remindedUser')
    },
  },
  extraReducers: {
    [authUser.pending]: (state) => {
      state.isLoading = true;
    },
    [authUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.user_2fa && isUndefined(action.payload.payload)) {
        state.user = action.payload;
        return;
      }
      const { type, message, payload } = action.payload;
      switch (type) {
        case "login_success":
          state.isLogin = true;
          state.user = payload.user;
          state.accessToken = payload.accessToken;
          window.localStorage.setItem("accessToken", payload.accessToken);

          window.location.href = "./";
          break;
        case "login_wrong_username":
          state.errorMessage = message;
          break;
        case "login_deactivated":
          state.errorMessage = message;
          break;
        case "login_wrong_password":
          state.errorMessage = message;
          state.loginAttempts = payload;
          break;
        case "login_locked":
          state.errorMessage = message;
          state.loginAttempts = 0;
          break;
        default:
          break;
      }
    },
    [authUser.rejected]: (state) => {
      state.isLoading = false;
      state.errorMessage = "";
    },
    [changePassword.pending]: (state) => {
      state.isLoadingChangePassword = true;
      state.errorMessage = "";
    },
    [changePassword.fulfilled]: (state, action) => {
      state.isLoadingChangePassword = false;
      if (action.payload === "invalid_old_password") {
        state.errorChangePassword = true;
        state.errorMessage = "Invalid Old Password.";
        return;
      }
      state.errorChangePassword = false;
      state.errorMessage = "";
    },
    [changePassword.rejected]: (state) => {
      state.isLoadingChangePassword = false;
    },
    [getUser.fulfilled]: (state, action) => {
      state.user = action.payload[0];
      if (action.payload.length <= 0) {
        window.localStorage.removeItem("accessToken");
        alert("Your account was deleted.");
        window.location.href = "./login";
      }
    },
    [getUser.rejected]: () => {
      window.localStorage.removeItem("accessToken");
      window.location.href = "./login";
    const expireTime = new Date(new Date().getTime() + 10000);
      Cookies.set("sessionExpired", "true", {
        expires: expireTime,
      });
    },
    [enableDisable2fa.pending]: (state) => {
      state.isLoadingToggle2fa = true;
    },
    [enableDisable2fa.fulfilled]: (state, action) => {
      state.isLoadingToggle2fa = false;
      state.is2faEnabled = action.payload;
    },
    [enableDisable2fa.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoadingToggle2fa = false;
    },
    [verifyPin.fulfilled]: (state, action) => {
      if(action.payload.verify){
        state.isVerifyPinLoading = false;
        state.isVerified = true;
        return
      }
      state.isVerified = false
      state.verifyPinError = action.payload.error
    },
    [verifyPin.rejected]: (state, action) => {
      console.log('Something Went Wrong. Verify OTP rejected.')
      state.isVerifyPinLoading = false;
    },
    [verifyPin.pending]: (state, action) => {
      state.isVerifyPinLoading = true;
    },
  },
});

export const { setRememberMe, logout } = userSlice.actions;
export default userSlice.reducer;
