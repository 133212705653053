/*
  Accounts Reducer
   This file manage the states of the Account module
*/

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const apiServer = process.env.REACT_APP_API_URL;
export const getAccounts = createAsyncThunk(
  "account/getAccounts",
  async (arg, { getState }) => {
    return await fetch(apiServer + "/getUsers", {
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
      },
    }).then((res) => res.json());
  }
);

export const createAccount = createAsyncThunk(
  "account/createAccount",
  async (form_data) => {
    return await fetch(apiServer + "/createAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(form_data),
    })
    .then((res) => res.json())
  }
);

export const updateAccount = createAsyncThunk(
  "account/updateAccount",
  async (form_data, { getState }) => {
    return await fetch(apiServer + "/updateAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(form_data),
    })
    .then((res) => res.json())
  }
);


export const updateUserProfile = createAsyncThunk(
  "account/profile/edit",
  async (form_data, { getState }) => {
    return await fetch(apiServer + `/profile/${form_data.emp_id}/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(form_data),
    })
    .then((res) => res.json())
  }
);

export const deleteAccount = createAsyncThunk(
  "account/deleteAccount",
  async (user_id, { getState }) => {
    return await fetch(apiServer + "/deleteAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({ user_id: user_id }),
    })
      .then((res) => res.json())
  }
);

export const getGroups = createAsyncThunk(
  'account/getGroups',
  async() => {
    return await fetch(apiServer + '/getGroups', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem("accessToken")
      }
    }).then(res => res.json())
  }
)

export const toggleAccountActivation  = createAsyncThunk(
  'account/toggleAccountActivation',
  async(args) => {
    return await fetch(apiServer + '/accountActivation', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('accessToken')
      },
      body: JSON.stringify(args)
    })
    .then(res => res.json());
  }
)

const initialState = {
  users: [],
  isLoading: false,
  groups: [],
  addIsLoading: false,
  updateIsLoading: false,
  userAdded: {},
  userUpdated: {}
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  extraReducers: {
    [getAccounts.pending]: (state) => {
      state.isLoading = true;
    },
    [getAccounts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    },
    [getAccounts.rejected]: (state) => {
      state.isLoading = false;
    },
    [getGroups.fulfilled]: (state, action) => {
      state.groups = action.payload
    },
    [toggleAccountActivation.fulfilled]: (action) => {
      console.log(action.payload)
    },
    [createAccount.pending]: (state) => {
      state.addIsLoading = true
    },
    [createAccount.fulfilled]: (state, action) => {
      state.addIsLoading = false
      state.userAdded = action.payload[0]
    },
    [createAccount.rejected]: (state) => {
      state.addIsLoading = false
      console.log('Something went wrong in adding a user.')
    },
    [updateAccount.pending]: (state) => {
      state.updateIsLoading = true
    },
    [updateAccount.fulfilled]: (state, action) => {
      state.updateIsLoading = false
      
      if(action.payload.isExist){
        toast.error(`${action.payload.role} role can only be assigned to a single account.`, {position: 'top-center'})
      }

      state.userUpdated = action.payload

    },
    [updateAccount.rejected]: (state) => {
      state.updateIsLoading = false
      console.log('Something went wrong in adding a user.')
    },
    [toggleAccountActivation.fulfilled]: (state, action) => {
      if(action.payload.isExist){
        toast.error(`You can only have one active ${action.payload.role} account.`, {position: 'top-center'})
      }else if(action.payload === 'activated' || action.payload === 'deactivated'){
        toast.success(`Account ${action.payload}`, {position: 'top-center'})
      }

    },
  },
});

export default accountsSlice.reducer;
