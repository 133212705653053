/*
  Revisions Reducer
   This file is responsible for managing states of the management of Revisions
*/
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const apiServer = process.env.REACT_APP_API_URL;

export const getRevisions = createAsyncThunk(
    'revisions/getRevisions',
    async(args) => {
        return await fetch(apiServer + '/dts/getRevisions', {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + window.localStorage.getItem("accessToken"),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(args)
        })
        .then(res => res.json())
    }
)

export const restoreCommunication = createAsyncThunk(
    'revisions/restoreCommunication',
    async(args) => {
        return await fetch(apiServer + '/dts/restoreCommunication', {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + window.localStorage.getItem("accessToken"),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(args)
        })
        .then(res => res.json())
    }
)


const initialState = {
    revisions: [],
    isLoading: false,
    restoredCommunication: {}
}

const revisionSlice = createSlice({
    name: "revisions",
    initialState,
    extraReducers: {
        [getRevisions.fulfilled]: (state, action) => {
            state.revisions = action.payload
            state.isLoading = false
        },
        [getRevisions.rejected]: (state, action) => {
            state.isLoading = false;
        },
        [getRevisions.pending] : state => {
            state.isLoading = true
        },
        [restoreCommunication.fulfilled]: (state, action) => {
            state.restoredCommunication = action.payload
            state.isLoading = false
        },
        [restoreCommunication.pending]: (state) => {
            state.isLoading = true
        },
        [restoreCommunication.rejected]: (state, action) => {
            state.isLoading = false
        },
    }
})
 
export default revisionSlice.reducer;