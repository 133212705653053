/*
  Dropdown menu of actions per row in the data tables.
*/
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  deleteAccount,
  getAccounts,
  toggleAccountActivation,
} from "../../../features/Portal/accountsSlice";
import RequirePermission from "../RequirePermission/RequirePermission";
import { toast } from "react-toastify";
const ActionDropdown = ({ userId, toggleEditAccountModal, userData }) => {
  const [dropdownOpen, toggleDropdown] = useState(false);
  const onToggleDropdown = () => toggleDropdown(!dropdownOpen);
  const dispatch = useDispatch();

  const onDeleteAccount = async (user_id) => {
    if (window.confirm("Are you sure you want to delete this account?")) {
      await dispatch(deleteAccount(user_id)).then((data) => {
        toast.success(`${data.payload}.`, {
          autoClose: 1500,
          position: "top-center",
          onOpen: () => dispatch(getAccounts()),
        });
      });
    }
  };

  const onToggleAccountActivation = async (status) => {
    if (
      window.confirm(
        `This will ${
          status !== "deactivated" ? "activate" : "deactivate"
        } the account. Proceed?`
      )
    ) {
      const res_activation = await dispatch(toggleAccountActivation({ user_id: userId }));
      if(res_activation.payload === 'activated' || res_activation.payload === 'deactivated'){
        dispatch(getAccounts())
      }
    }
  };

  return (
    <Dropdown toggle={() => onToggleDropdown()} isOpen={dropdownOpen}>
      <DropdownToggle
        caret
        size="sm"
        color="secondary"
        onClick={onToggleDropdown}
      >
        Action
      </DropdownToggle>
      <DropdownMenu container="body">
        <RequirePermission
          allowedPermissions={["assignRoleToUser", "manageAccounts"]}
        >
          <DropdownItem onClick={() => toggleEditAccountModal()}>
            Edit
          </DropdownItem>
        </RequirePermission>
        <RequirePermission allowedPermissions={["activateUser"]}>
          <DropdownItem
            onClick={() =>
              onToggleAccountActivation(userData.user_accountStatus)
            }
          >
            {userData.status === "deactivated"
              ? "Activate"
              : "Deactivate"}
          </DropdownItem>
        </RequirePermission>
        <RequirePermission allowedPermissions={["manageAccounts"]}>
          <DropdownItem onClick={() => onDeleteAccount(userId)}>
            Delete
          </DropdownItem>
        </RequirePermission>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ActionDropdown;
