/*
  This component is controlling the page view for unauthorize access from unauthenticated users.
*/
import React, { useEffect } from "react";
import { Navigate,  useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../features/Portal/userSlice";

const ProtectedRoute = ({ children} ) => {
  const location = useLocation();
  const accessToken = window.localStorage.getItem("accessToken");
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  useEffect(() => {
    if(user.user_2fa && !window.localStorage.getItem('accessToken')){
      return navigate('/authenticate')
    }
    if(!user || !user.user_id) dispatch(getUser());
  }, [dispatch])

  if (accessToken || user.user_2fa) {
    return <>{children}</>;
  } else {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }
};

export default ProtectedRoute;
